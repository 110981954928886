<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#42275a"
        spinner="spinner" />
      <v-flex xs12 lg11>
        <v-layout wrap justify-center>
          <v-flex xs12 lg11 text-center pt-7 pb-2>
            <span style="font-size: 23px;font-weight: bold;">Edit Application form for film Shooting</span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex pa-4 xs12 text-center>
            <v-card>
              <v-layout wrap justify-start pa-4>

                <!-- <v-flex xs11 lg3 pl-3 pr-3 pt-3 pb-6 text-left align-self-center>
                  <v-text-field label="Name of forest Division Office" dense color="#283e51" outlined
                    background-color="white" v-model="list.nameofOffice" hide-details>
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">
                        Name of forest Division Office

                      </span>
                    </template>
                  </v-text-field>
                </v-flex> -->
                <v-flex pr-3 xs11 lg3 pt-3 pb-6 text-left align-self-center>
                  <v-text-field label="Mobile Number" type="text" dense color="#283e51" outlined background-color="white"
                    v-model="list.applicantName" hide-details>
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">
                        Name of the Applicant

                      </span>
                    </template>
                  </v-text-field>
                </v-flex>

                <v-flex pr-3 xs11 lg3 pt-3 pb-6 text-left align-self-center>
                  <v-text-field label="Mobile Number" type="number" :rules="PhoneRules" dense color="#283e51" outlined
                    class="inputPrice" background-color="white" v-model="list.applicantMobileNo" hide-details>
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">
                        Mobile Number

                      </span>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex pl-3 pr-3 xs11 lg3 pt-3 pb-6 text-left align-self-center>
                  <v-text-field label="Email" :rules="emailRules" dense color="#283e51" outlined background-color="white"
                    v-model="list.applicantEmail" hide-details>
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">
                        Email

                      </span>
                    </template>
                  </v-text-field>
                </v-flex>

                <v-flex pl-3 pr-3 xs11 lg3 pt-3 pb-6 text-left align-self-center>
                  <v-text-field label="Address" dense color="#283e51" outlined background-color="white"
                    v-model="list.applicantAddress" hide-details>
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">
                        Address

                      </span>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex xs3 pb-3 pr-3 text-left align-self-center>
                  <v-autocomplete outlined dense color="black" :items="districts" v-model="list.applicantDistrict"
                    label="District" item-text="name" item-value="name" item-color="#FF1313" hide-details clearable
                    class="custom-autocomplete mainfont">
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">District
                      </span>
                    </template>
                    <template v-slot:append>
                      <v-icon color="black">mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex pr-3 xs11 lg3 pt-3 pb-6 text-left align-self-center>
                  <v-text-field label="Pincode" type="number" dense color="#283e51" outlined background-color="white"
                    class="inputPrice" v-model="list.applicantPinCode" hide-details>
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">
                        Pincode

                      </span>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex pl-3 pr-3 xs11 lg3 pt-3 pb-6 text-left align-self-center>
                  <v-text-field label="Nature of Application" dense color="#283e51" outlined background-color="white"
                    v-model="list.natureOfApplication" hide-details>
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">
                        Nature of Application

                      </span>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex pl-3 xs3 pb-3 pr-3 text-left align-self-center>
                  <v-autocomplete outlined dense color="black" :items="idcardtypes" v-model="list.idType"
                    label="Id Card Type" item-text="name" item-value="name" item-color="#FF1313" hide-details clearable
                    class="custom-autocomplete mainfont">
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">Id card
                        type
                      </span>
                    </template>
                    <template v-slot:append>
                      <v-icon color="black">mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex pr-3 xs3 pt-3 pb-6 text-left align-self-center>
                  <v-text-field label="Id Card Number" dense color="#283e51" outlined class="inputPrice"
                    background-color="white" v-model="list.idcardNo" hide-details>
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">Id Card Number

                      </span>
                    </template>
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap justify-center>
              <v-flex pa-4 xs12 text-center>
                <v-card>
                  <template>
                    <div>
                      <v-layout wrap>
                        <v-flex pl-4 pt-2 pb-2 text-left class="bgcustom">
                          <v-layout wrap justify-start>
                            <v-flex xs5 pt-2 align-self-center>
                              <span style="font-size: 20px;color: white;">
                                Add Details of film shooting

                              </span>&nbsp;
                              <v-btn small color="#68D389" @click="addLeaderDetails()">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap justify-start style="background-color: white;">
                        <v-card flat>
                          <v-layout wrap>
                            <v-flex pt-1 v-for="(item, i) in leaderDetails2" :key="i">
                              <v-layout wrap pt-2>
                                <v-flex lg3 pt-8 pt-lg-0 pa-3>

                                  <v-text-field color="black" v-model="item.location" dense outlined label="Name"
                                    hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        Location</span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
                                <v-flex lg3 pt-8 pt-lg-0 pa-3>

                                  <v-text-field color="black" v-model="item.filmName" dense outlined label="Name"
                                    hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        Film Name</span>
                                    </template>
                                  </v-text-field>
                                </v-flex>

                                <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                  <v-menu ref="menu1" v-model="item.startDate" :close-on-content-click="false"
                                    max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field clearable dense outlined readonly v-model="item.fromDate"
                                        v-bind="attrs" background-color="white" v-on="on"
                                        @click:clear="item.fromDate = null" style="color: black">
                                        <template v-slot:append>
                                          <v-icon color="black">mdi-chevron-down</v-icon>
                                        </template>
                                        <template v-slot:label>
                                          <span class="custom-label-text" style="color: black; font-size: 14px">
                                            From Date
                                          </span>
                                        </template>
                                      </v-text-field>
                                    </template>
                                    <v-date-picker v-model="item.fromDate" color="#13736f"
                                      @change="item.startDate = false"></v-date-picker>
                                  </v-menu>
                                </v-flex>

                                <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                  <v-menu ref="menu2" v-model="item.endDate" :close-on-content-click="false"
                                    max-width="290" elevation="0">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field clearable label="To Date" readonly dense background-color="white"
                                        color="#13736f" outlined v-model="item.toDate" v-bind="attrs" v-on="on"
                                        @click:clear="item.toDate = null">
                                        <template v-slot:append>
                                          <v-icon color="black">mdi-chevron-down</v-icon>
                                        </template>
                                        <template v-slot:label>
                                          <span class="custom-label-text" style="color: black; font-size: 14px">
                                            To Date
                                          </span>
                                        </template>
                                      </v-text-field>
                                    </template>
                                    <v-date-picker v-model="item.toDate" color="#13736f"
                                      @change="item.endDate = false"></v-date-picker>
                                  </v-menu>
                                </v-flex>



                                <v-flex lg3 pt-8 pt-lg-0 pa-3>

                                  <v-text-field color="black" type="number" v-model="item.noOfMembers" dense outlined
                                    class="inputPrice" label="Email" hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        No. of Crew Members
                                      </span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
                                <v-flex lg3 pt-8 pt-lg-0 pa-3>

                                  <v-text-field color="black" type="number" v-model="item.noOfVehicles" dense outlined
                                    class="inputPrice" label="Email" hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        No. of Vehicle
                                      </span>
                                    </template>
                                  </v-text-field>
                                </v-flex>

                                <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                  <v-btn dark style="background-color: red" dense @click="removeLeaderDetail(i)">
                                    Remove
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-layout>
                      <v-layout wrap justify-start style="background-color: white;">
                        <v-card flat>
                          <v-layout wrap>
                            <v-flex pt-1 v-for="(item, i) in leaderDetails" :key="i">
                              <v-layout wrap pt-2>
                                <v-flex lg3 pt-8 pt-lg-0 pa-3>

                                  <v-text-field color="black" v-model="item.location" dense outlined label="Name"
                                    hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        Location</span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
                                <v-flex lg3 pt-8 pt-lg-0 pa-3>

                                  <v-text-field color="black" v-model="item.filmName" dense outlined label="Address1"
                                    hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        Film Name</span>
                                    </template>
                                  </v-text-field>
                                </v-flex>


                                <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                  <v-menu ref="menu1" v-model="item.startDate" :close-on-content-click="false"
                                    max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field clearable dense outlined readonly v-model="item.fromDate"
                                        v-bind="attrs" background-color="white" v-on="on"
                                        @click:clear="item.fromDate = null" style="color: black">
                                        <template v-slot:append>
                                          <v-icon color="black">mdi-chevron-down</v-icon>
                                        </template>
                                        <template v-slot:label>
                                          <span class="custom-label-text" style="color: black; font-size: 14px">
                                            From Date
                                          </span>
                                        </template>
                                      </v-text-field>
                                    </template>
                                    <v-date-picker v-model="item.fromDate" color="#13736f"
                                      @change="item.startDate = false"></v-date-picker>
                                  </v-menu>
                                </v-flex>
                                <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                  <v-menu ref="menu2" v-model="item.endDate" :close-on-content-click="false"
                                    max-width="290" elevation="0">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field clearable label="To Date" readonly dense background-color="white"
                                        color="#13736f" outlined v-model="item.toDate" v-bind="attrs" v-on="on"
                                        @click:clear="item.toDate = null">
                                        <template v-slot:append>
                                          <v-icon color="black">mdi-chevron-down</v-icon>
                                        </template>
                                        <template v-slot:label>
                                          <span class="custom-label-text" style="color: black; font-size: 14px">
                                            To Date
                                          </span>
                                        </template>
                                      </v-text-field>
                                    </template>
                                    <v-date-picker v-model="item.toDate" color="#13736f"
                                      @change="item.endDate = false"></v-date-picker>
                                  </v-menu>
                                </v-flex>



                                <v-flex lg3 pt-8 pt-lg-0 pa-3>

                                  <v-text-field color="black" type="number" v-model="item.noOfMembers" dense outlined
                                    label="Email" hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        No. of Crew Members
                                      </span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
                                <v-flex lg3 pt-8 pt-lg-0 pa-3>

                                  <v-text-field color="black" type="number" v-model="item.noOfVehicles" dense outlined
                                    label="Email" hide-details>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">
                                        No. of Vehicle
                                      </span>
                                    </template>
                                  </v-text-field>
                                </v-flex>

                                <v-flex lg2>
                                  <center>
                                    <v-btn dark style="background-color: #68d389" dense @click="removeleaderdetails(i)">
                                      Remove
                                    </v-btn>
                                  </center>

                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>

                      </v-layout>
                    </div>
                  </template>


                </v-card>

              </v-flex>
              <v-flex pa-4 xs12 text-center>
                <v-card>
                  <v-layout wrap>
                    <v-flex xs12 text-center>
                      <v-card>
                        <v-layout wrap justify-center>
                          <v-flex xs12>
                            <template>
                              <div>
                                <v-layout wrap justify-center>
                                  <v-flex pl-4 pt-2 pb-2 xs12 text-left class="bgcustom">
                                    <span style="font-size: 20px; color: white">
                                      Animal Details
                                    </span>
                                  </v-flex>
                                  <v-flex xs11 pb-6 pt-6>
                                    <v-layout wrap justify-start>
                                      <v-flex xs11 lg12 pa-2>
                                        <v-autocomplete clearable dense outlined color="black" :items="animal"
                                          v-model="list.animalinvolved" item-color="#FF1313" hide-details
                                          class="custom-autocomplete mainfont">
                                          <template v-slot:label>
                                            <span class="custom-label-text" style="color: black; font-size: 14px">
                                              Whether any animal is involved in film shooting?
                                            </span>
                                          </template>
                                          <template v-slot:append>
                                            <v-icon color="black">mdi-chevron-down</v-icon>
                                          </template>
                                        </v-autocomplete>
                                      </v-flex>
                                    </v-layout>
                                    <v-layout wrap justify-start v-if="list.animalinvolved === 'YES'">
                                      <v-flex xs12 lg3 pa-3>
                                        <v-text-field color="black" v-model="list.species" dense outlined label="Name"
                                          hide-details>
                                          <template v-slot:label>
                                            <span class="custom-label-text" style="color: black; font-size: 14px">
                                              Species Name
                                              <span style="color: rgb(255, 0, 0)">*</span></span>
                                          </template>
                                        </v-text-field>
                                      </v-flex>
                                      <v-flex xs12 lg3 pa-3>
                                        <v-text-field color="black" type="number" hide-spin-buttons
                                          v-model="list.noOfAnimal" dense outlined hide-details="auto" class="inputPrice">
                                          <template v-slot:label>
                                            <span class="custom-label-text" style="color: black; font-size: 14px">
                                              No. of Animals
                                              <span style="color: rgb(255, 0, 0)">*</span>
                                            </span>
                                          </template>
                                        </v-text-field>
                                      </v-flex>
                                      <v-flex xs12 lg3 pa-3>
                                        <v-text-field color="black" type="string" hide-spin-buttons
                                          v-model="list.preshootNumber" dense outlined hide-details="auto">
                                          <template v-slot:label>
                                            <span class="custom-label-text" style="color: black; font-size: 14px">
                                              Preshoot Order Number
                                              <span style="color: rgb(255, 0, 0)">*</span>
                                            </span>
                                          </template>
                                        </v-text-field>
                                      </v-flex>
                                      <v-flex xs12 lg3 pa-3>
                                        <v-menu ref="menu3" v-model="validitystartDate" :close-on-content-click="false"
                                          max-width="290">
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-text-field hide-details="true" clearable dense outlined readonly
                                              v-model="list.validityfromDate" v-bind="attrs" background-color="white"
                                              v-on="on" @click:clear="validityfromDate = null" style="color: black">
                                              <template v-slot:append>
                                                <v-icon color="black">mdi-chevron-down</v-icon>
                                              </template>
                                              <template v-slot:label>
                                                <span class="custom-label-text" style="
                                              color: black;
                                              font-size: 14px;
                                            ">
                                                  Validity From Date
                                                  <span style="color: rgb(255, 0, 0)">*</span>
                                                </span>
                                              </template>
                                            </v-text-field>
                                          </template>
                                          <v-date-picker v-model="list.validityfromDate" color="#13736f"
                                            :min="minDatee"></v-date-picker>
                                        </v-menu>
                                      </v-flex>
                                      <v-flex xs12 lg3 pa-3>
                                        <v-menu ref="menu4" v-model="validityendDate" :close-on-content-click="false"
                                          max-width="290">
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-text-field hide-details="true" clearable dense outlined readonly
                                              v-model="list.validitytoDate" v-bind="attrs" background-color="white"
                                              v-on="on" @click:clear="validitytoDate = null" style="color: black">
                                              <template v-slot:append>
                                                <v-icon color="black">mdi-chevron-down</v-icon>
                                              </template>
                                              <template v-slot:label>
                                                <span class="custom-label-text" style="
                                              color: black;
                                              font-size: 14px;
                                            ">
                                                  Validity To Date
                                                  <span style="color: rgb(255, 0, 0)">*</span>
                                                </span>
                                              </template>
                                            </v-text-field>
                                          </template>
                                          <v-date-picker v-model="list.validitytoDate" color="#13736f"
                                            :min="list.validityfromDate"></v-date-picker>
                                        </v-menu>
                                      </v-flex>
                                    </v-layout>
                                    <!-- <v-layout wrap justify-center>
                                      <v-flex xs11 lg6 text-center>
                                        <label for="Preshootfile">
                                          <span>Preshoot Order Upload
                                            <span style="color: rgb(255, 0, 0)">*</span>
                                          </span>
                                        </label>
                                      </v-flex>
                                      <v-flex xs11 lg6 pt-1>
                                        <input type="file" id="Preshootfile" ref="Preshootfile"
                                          @change="handleFileChange2('Preshootfile')" />
                                      </v-flex>
                                    </v-layout> -->
                                    <v-layout wrap justify-center v-if="list.animalinvolved === 'YES'">
                                      <v-flex xs4 pt-7 text-left>
                                        <label for="Preshootfile">
                                          <span>Preshoot Order Upload<span style="color:red;font-size:23px">*</span>
                                          </span>
                                        </label>
                                      </v-flex>
                                      <v-flex xs4 pt-6 v-if="list.Preshootfile">
                                        <v-btn color="#3CB043" dark download :href="mediaURL + Preshootfile"
                                          target="_blank"><span
                                            style="text-transform: none; font-family: mainfont; font-size: 12px;">View
                                            Pdf</span></v-btn>
                                      </v-flex>
                                      <v-flex xs11 lg4 pt-6 pr-16>
                                        <input type="file" id="Preshootfile" accept=".pdf, .jpeg, .png" ref="Preshootfile"
                                          @change="handleFileChange2('Preshootfile')" />
                                      </v-flex>


                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </template>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>


              <v-flex pa-4 xs12 text-center>
                <v-card>
                  <v-layout wrap>
                    <v-flex xs12 text-center>
                      <v-card>
                        <v-layout wrap justify-center>
                          <v-flex xs12>
                            <template>
                              <div>
                                <v-layout wrap justify-center>
                                  <v-flex pl-4 pt-2 pb-2 xs12 text-left class="bgcustom">
                                    <span style="font-size: 20px;color: white;">
                                      Scanned Documents
                                    </span>
                                  </v-flex>
                                  <v-flex xs12 text-center pa-4>
                                    <span style="font-size: 20px;color: black;">
                                      (Documents must be PDF format. File size cannot exceed 100 KB)
                                    </span>
                                  </v-flex>
                                  <v-flex xs11 pb-6>
                                    <v-layout wrap justify-center>
                                      <v-flex xs4 pt-7 text-left>
                                        <label for="idProof">
                                          <span>ID proof <span style="color:red;font-size:23px">*</span>
                                          </span>
                                        </label>
                                      </v-flex>
                                      <v-flex xs4 pt-6>
                                        <v-btn color="#3CB043" dark download :href="mediaURL + idProof"
                                          target="_blank"><span
                                            style="text-transform: none; font-family: mainfont; font-size: 12px;">View
                                            Pdf</span></v-btn>
                                      </v-flex>
                                      <v-flex xs11 lg4 pt-6 pr-16>
                                        <input type="file" id="idProof" accept=".pdf, .jpeg, .png" ref="idProof"
                                          @change="handleFileChange('idProof')" />
                                      </v-flex>


                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </template>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12 pb-10>
                <v-layout wrap justify-end>
                  <v-flex xs2 pr-4>
                    <v-btn @click="edit()" color="black" block type="submit">
                      <span style="color:white">SUBMIT</span>
                    </v-btn>
                  </v-flex>

                </v-layout>


              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  
<script>
import axios from "axios";
import moment from "moment";
// import CryptoJS from "crypto-js";

export default {
  components: {
  },
  data() {

    const today = new Date().toISOString().substr(0, 10);
    return {
      animal: ["YES",
        "NO"],
      animalinvolved: '',
      menu3: false,
      menu4: false,
      noOfAnimal: '',
      preshootNumber: '',
      species: '',
      validityfromDate: '',
      validitytoDate: '',
      Preshootfile: '',
      validitystartDate: null,
      validityendDate: null,
      idProof: {},
      reqLetter: {},
      isAgree: false,
      participantfile: {},
      leaderIdProof: {},
      cdate: {},
      memberDetails: [],
      minDatee: null,
      memberDetails2: [],
      memberdialog: false,
      leaderName: null,
      leaderAddress1: null,
      leaderAddress2: null,
      telephone: null,
      fromDate: new Date(),
      toDate: new Date(),
      idCardType: null,
      leaderPhone: null,
      leaderGender: null,
      leaderEmail: null,
      district: null,
      instHeadName: null,
      list2: {},
      dist: null,
      headDesignation: null,
      idCardTypeidCardType: null,
      idNo: null,
      idcardtypes: [
        "Election Id Card",
        "Driving License",
        "Aadhaar Card",
      ],
      instName: null,
      instCode: null,
      instType: null,
      instAddress: null,
      instTeleNo: null,
      instMobileNo: null,
      instEmail: null,
      institutionTypes: [
        "Government",
        "Aided",
        "Private",
        "Others"
      ],
      startDate: today,
      showSnackBar: false,
      isChecked: false,
      appLoading: false,
      msg: "",
      expandSection: false,
      name: null,
      barlist: [],
      list: {},
      selectedDistrict: null,
      districts: [
        "Thiruvananthapuram",
        "Kollam",
        "Pathanamthitta",
        "Alappuzha",
        "Kottayam",
        "Idukki",
        "Ernakulam",
        "Thrissur",
        "Palakkad",
        "Malappuram",
        "Kozhikode",
        "Wayanad",
        "Kannur",
        "Kasaragod"
      ],
      campDuration: '',
      menu1: false,
      submitdialog: false,
      male: null,
      leaderDetails2: [],
      memberCount: null,
      leaderDetails: [],

      menu2: false,
      applicantCategory: "",
      applicantname: null,
      id:this.$route.query.id,


    };
  },

  // },
  created() {
    this.dist = this.$route.query.district;

  },
  computed: {
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    },
    PhoneRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
      ];
    },
    emailRules() {
      return [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },


  },
  mounted() {
    this.getList();
    this.getCircle();

    // this.checkAvailability();
  },


  methods: {
    getCircle() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/get/circles",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
        },
      })
        .then((response) => {
          this.circle = response.data.data;

          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    submit2() {
      this.cdate = null;
      this.memberdialog = false;
    },
    handleFileChange(field) {
      this[field] = this.$refs[field].files[0];
    },
    handleFileChange2(field) {
      this[field] = this.$refs[field].files[0];
    },
    validateFile(file) {
      return file.size <= 100 * 1024;
    },
    removeMember(index) {
      this.memberDetails2.splice(index, 1);
    },
    removeLeader(index) {
      this.leaderDetails2.splice(index, 1);
    },
    removeLeaderDetail(index) {
      this.leaderDetails2.splice(index, 1);
    },
    checkAvailability(cdate) {
      axios({
        method: "POST",
        url: "/check/availability",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          date: cdate,
          campid: this.campidx,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.list2 = response.data.natureCampDetails;
            this.checkstatus = response.data.data;

            // Check if checkstatus is equal to 0
            if (this.checkstatus === 0) {
              this.memberdialog = true;
            }

            console.log("message is ", this.checkstatus);
          } else {
            this.memberdialog = true;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },


    addLeaderDetails() {
      this.leaderDetails.push({ location: '', fromDate: '', toDate: '', noOfMembers: '', noOfVehicles: '', filmName: '' },);
      this.location = '';
      this.filmName = '';
      this.fromDate = '';
      this.toDate = '';
      this.noOfMembers = '';
      this.noOfVehicles = '';

    },

    removeValues() {
      this.leaderDetails.pop();
    },
    removeleaderdetails(index) {
      this.leaderDetails.splice(index, 1);
    },
    submit() {
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      axios({
        method: "POST",
        url: "/final/submit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,

        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.submitdialog = false;
            this.$router.push({ path: "/ForwardedApplications" });
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    addValues() {
      this.memberDetails.push({ memberGender: '', memberDob: '', memberName: '' });
    },
    removeVehicle(index) {
      this.memberDetails.splice(index, 1);
    },
    edit() {
      this.appLoading = true;
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      this.leaderDetails.forEach((item) => {
        this.leaderDetails2.push({ location: item.location, filmName: item.filmName, fromDate: item.fromDate, toDate: item.toDate, noOfMembers: item.noOfMembers, noOfVehicles: item.noOfVehicles },);
      });
      const formData = new FormData();
      formData.append("filmShootingDetails", JSON.stringify(this.leaderDetails2));
      formData.append("nameofOffice", this.list.nameofOffice);
      formData.append("applicantMobileNo", this.list.applicantMobileNo);
      formData.append("applicantEmail", this.list.applicantEmail);
      formData.append("applicantAddress", this.list.applicantAddress);
      formData.append("applicantDistrict", this.list.applicantDistrict);
      formData.append("applicantPinCode", this.list.applicantPinCode);
      formData.append("idType", this.list.idType);
      formData.append("idcardNo", this.list.idcardNo);
      formData.append("applicantName", this.list.applicantName);
      formData.append("appid", this.id);
      formData.append("divisionid", this.list.divisionid);
      formData.append("idProof", this.idProof);
      formData.append("Preshootfile", this.list.Preshootfile);
      formData.append("animalinvolved", this.list.animalinvolved);
      formData.append("species", this.list.species);
      formData.append("noOfAnimal", this.list.noOfAnimal);
      formData.append("preshootNumber", this.list.preshootNumber);
      formData.append("validityfromDate", this.list.validityfromDate);
      formData.append("validitytoDate", this.list.validitytoDate);
      axios({
        method: "POST",
        url: "/edit/application",
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),

        },
        data: formData,
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
            window.location.reload();
            this.$router.push({ path: "/ForwardedApplications" });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },

    getList() {
      this.appLoading = true;
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      axios({
        method: "POST",
        url: "/single/application",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.list = response.data.data;
            if (this.list.validityfromDate) {
              this.list.validityfromDate = moment(this.list.validityfromDate).format("YYYY-MM-DD");
            }
            if (this.list.validitytoDate) {
              this.list.validitytoDate = moment(this.list.validitytoDate).format("YYYY-MM-DD");
            }
            this.leaderDetails2 = this.list.filmShootingDetails;
            this.leaderDetails2.forEach((detail) => {
              detail.fromDate = moment(detail.fromDate).format("YYYY-MM-DD");
              detail.toDate = moment(detail.toDate).format("YYYY-MM-DD");
            });
            this.idProof = this.list.idProof;
            this.Preshootfile = this.list.Preshootfile;
            this.cdate = this.list.startDate;
            this.cdate = moment(this.cdate).format("YYYY-MM-DD");
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
  },
};
</script>
<style>
.no-spinners {
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>